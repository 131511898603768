<template>
  <div
    class="d-flex"
    style="width: 100%; height: 100%"
  >
    <template v-if="companyInfo">
      <v-img
        class="me-5"
        max-height="150"
        max-width="180"
        contain
        :src="'https://finance.vietstock.vn/image/' + symbol"
      ></v-img>
      <div>
        <p class="title mb-2 text-dark">
          {{ companyInfo.name }}
        </p>
        <p class="mb-2 text-dark">
          Sàn giao dịch: {{ companyInfo.exchange }}
        </p>
        <p class="mb-2 text-dark">
          Ngành: {{ companyInfo.industry }}
        </p>
        <v-btn
          class="mt-3"
          small
          color="primary"
          target="_blank"
          :href="'/company/summary/' + symbol.toUpperCase()"
        >
          Xem chi tiết
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  data() {
    return {
      symbol: null,
    }
  },

  computed: {
    companyInfo() {
      return this.$store.getters['company/getBySymbol'](this.symbol)
    },
  },

  watch: {
    companyInfo(value) {
      window.parent.postMessage({
        event: 'search-result',
        success: value !== false,
      }, '*')
    },
  },

  created() {
    this.symbol = this.$route.params.symbol
    this.$store.dispatch('company/FETCH_COMPANIES')
  },

}
</script>

<style>
  .text-dark {
    color: rgb(58, 53, 65)
  }
</style>
